// When changing languages list also do not forget to change ./languagesListForMoment
export const languagesList = [
    { value: 'id-ID', name: 'Bahasa Indonesia' },
    { value: 'ms', name: 'Bahasa Melayu' },
    { value: 'my', name: 'Burmese' },
    { value: 'cs', name: 'Čeština' },
    { value: 'da', name: 'Dansk' },
    { value: 'de', name: 'Deutsch' },
    { value: 'et', name: 'Eesti' },
    { value: 'en', name: 'English' },
    { value: 'es', name: 'Español' },
    { value: 'fr', name: 'Français' },
    { value: 'hr', name: 'Hrvatski' },
    { value: 'it', name: 'Italiano' },
    { value: 'km', name: 'Khmer' },
    { value: 'sw', name: 'Kiswahili' },
    { value: 'lv', name: 'Latviešu' },
    { value: 'lt', name: 'Lietuvių' },
    { value: 'hu', name: 'Magyar' },
    { value: 'nl', name: 'Nederlands' },
    { value: 'no', name: 'Norsk' },
    { value: 'pl', name: 'Polski' },
    { value: 'pt', name: 'Português' },
    { value: 'pt-BR', name: 'Português (Brasileiro)' },
    { value: 'ro', name: 'Română' },
    { value: 'sk', name: 'Slovenčina' },
    { value: 'sl', name: 'Slovenščina' },
    { value: 'fi', name: 'Suomi' },
    { value: 'sv', name: 'Svenska' },
    { value: 'tl', name: 'Tagalog' },
    { value: 'vi', name: 'Tiếng Việt' },
    { value: 'tr', name: 'Türkçe' },
    { value: 'el', name: 'Ελληνικά' },
    { value: 'bg', name: 'Български' },
    { value: 'ru', name: 'Русский' },
    { value: 'sr', name: 'Српски' },
    { value: 'uk', name: 'Українська' },
    // { value: 'ar', name: 'العربية' },
    // { value: 'fa', name: 'فارسی' },
    { value: 'ka', name: 'ქართული' },
    // { value: 'he', name: 'עברית' },
    { value: 'hi', name: 'हिन्दी' },
    // { value: 'ta', name: 'தமிழ்' },
    { value: 'th', name: 'ภาษาไทย' },
    { value: 'ko', name: '한국어' },
    // { value: 'lo', name: 'ພາສາລາວ' },
    { value: 'zh-CN', name: '中文(简体)' },
    // { value: 'zh', name: '中文(繁體)' }
    { value: 'ja', name: '日本語' },
] as const;

export const FALLBACK_LANGUAGE: Language = 'en';

export const LanguageValues = languagesList.map(l => l.value);

export type Language = typeof LanguageValues[number];

export type LanguageValue = { value: Language; name: string };

export const Languages = languagesList as unknown as Array<LanguageValue>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isInLanguagesList = (lang: any): lang is Language => LanguageValues.includes(lang);
