import produce from 'immer';
import moment from 'moment';
import { CHANGE_LANGUAGE, LocalizationAction, LocalizationState } from './localization.types';
import { ThunkActionVoid } from '../index';
import { Language, FALLBACK_LANGUAGE } from '../../localization/localizationDefaultData';
import {
    HYDRATE_STATE_FROM_STORAGE,
    HydrateStateFromStorageAction,
} from '../initialLoader/initialLoader.types';
import i18n from '../../localization/i18n';
import {
    getDefaultLanguage,
    getImpersonateMode,
    getSupportedLanguages,
} from '../portalSettings/portalSettingsSelector';
import { getUserLanguage } from '../user/userSelector';
import { getLanguage } from './localizationSelector';

export const initialState: LocalizationState = {
    language: FALLBACK_LANGUAGE,
};

export const reducer = (
    state = initialState,
    action: LocalizationAction | HydrateStateFromStorageAction,
): LocalizationState =>
    produce(state, (draft: LocalizationState) => {
        switch (action.type) {
            case HYDRATE_STATE_FROM_STORAGE:
                const { localization } = action.state;
                if (!localization) return draft;
                return localization as LocalizationState;
            case CHANGE_LANGUAGE:
                draft.language = action.language;
                return draft;
            default:
                return draft;
        }
    });

export const localizationCreator = {
    changeLanguage:
        (language: Language): ThunkActionVoid =>
        dispatch => {
            moment.locale(language);
            return dispatch({
                language,
                type: CHANGE_LANGUAGE,
            });
        },

    checkLanguageAfterLogin:
        (): ThunkActionVoid =>
        (dispatch, getState, { userCreator }) => {
            const state = getState();
            const currentLanguage = getLanguage(state);
            const userLanguage = getUserLanguage(state);
            const supportedLanguages = getSupportedLanguages(state);
            const defaultLanguage = getDefaultLanguage(state);
            const impersonateMode = getImpersonateMode(state);

            const isCurrentLanguageSupported = supportedLanguages.includes(currentLanguage);
            const isUserLanguageSupported = supportedLanguages.includes(userLanguage);

            if (
                isCurrentLanguageSupported &&
                userLanguage !== currentLanguage &&
                !impersonateMode
            ) {
                moment.locale(currentLanguage);
                dispatch(userCreator.changeUserLanguage(currentLanguage));
            } else if (
                (!isCurrentLanguageSupported || impersonateMode) &&
                isUserLanguageSupported
            ) {
                moment.locale(userLanguage);
                i18n.changeLanguage(userLanguage);
            } else if (!isCurrentLanguageSupported && !isUserLanguageSupported) {
                moment.locale(defaultLanguage);
                i18n.changeLanguage(defaultLanguage);
            }
        },
};
