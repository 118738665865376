import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'core-js/features/string/repeat';

import './utils/moment.utils';

import React from 'react';
import ReactDOM from 'react-dom';
import { jssPreset, StylesProvider } from 'worldapp-ui/shared';
import { create } from 'jss';
// import rtl from 'jss-rtl';
import moment from 'moment';
import ReactGA from 'react-ga4';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import './index.css';
import './localization/i18n';
import { updateBrandedFavicons } from './utils/favicons.utils';

ReactGA.initialize('G-GVJVDVM8D1');
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

smoothscroll.polyfill();
moment.locale(navigator.language);

updateBrandedFavicons();

// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const jss = create({ plugins: [...jssPreset().plugins] });

ReactDOM.render(
    <StylesProvider jss={jss}>
        <App />
    </StylesProvider>,
    document.getElementById('root'),
);
